<template>
  <div class="login login-v2" data-pageload-addclass="animated fadeIn">
      <!-- begin brand -->
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span> <b>P2C</b>
        </div>
      </div>
      <div class="login-content">
        <h3 class="text-muted font-weight-normal mb-4">
          <span class="text-first-letter">{{ title }}</span>
        </h3>
         <span class="text-first-letter">{{ subtitle }}.</span>
        <br>
          <span class="text-first-letter">{{ text }}.</span>
        <br>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TYPES as AUTH_TYPES } from './store'
import { ROUTES as AUTH_ROUTES } from './router'

export default {
  name: 'UserAccountActivation',
  data () {
    return {
      uid: this.$route.params.uid,
      token: this.$route.params.token,
      title: this.$t('Activating user, please wait....'),
      subtitle: undefined,
      text: undefined
    }
  },
  methods: {
    ...mapActions({
      activateUser: AUTH_TYPES.AUTH.activateUser
    }),
    goToLogin () {
      this.$router.replace({ name: AUTH_ROUTES.LOGIN })
    }
  },
  mounted () {
    this.activateUser({
      uid: this.uid,
      token: this.token
    })
      .then(() => {
        this.title = this.$t('Thank You for activating your user')
        this.subtitle = this.$t('Your email has been successfully verified')
        this.text = this.$t('From this time you can use your email and your password to access the site')
        setTimeout(() => {
          this.goToLogin()
        }, 5000)
      })
      .catch((e) => {
        console.log(e)
        this.title = `${this.$t('Error verifying your email')}. ${e.response.data.link}`
        this.subtitle = this.$t('Please retry later')
        this.text = this.$t('If the error persist, please contact the system administrator')
      })
  }
}
</script>
